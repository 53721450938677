function menuHamburger($) {
  jQuery(".hamburger").on("click", function (e) {
    e.preventDefault();
    jQuery(this).toggleClass("active");
    jQuery(".header-nav").toggleClass("is-show");
    jQuery("body").toggleClass("overflow-hidden");
  });
}

function wowo($) {
  setTimeout(function () {
    jQuery("html").addClass("show-c");
    var wTop = jQuery(window).scrollTop(),
      wHeight = jQuery(window).height(),
      wBottom = wTop + wHeight;
    jQuery(".wowo:not(.animated)").each(function () {
      var me = jQuery(this),
        meTop = me.offset().top,
        meHeight = me.height(),
        meBottom = meTop + meHeight,
        limitTop = wTop - meHeight,
        limitBottom = wBottom + meHeight;
      if (meTop > limitTop && meBottom < limitBottom) {
        me.addClass("animated");
        // setTimeout(function () {
        //     me.removeClass("animated wowo");
        // }, 1500);
      }
    });
  }, 100);
}

function search($) {
  jQuery(".search-icon").click(function () {
    jQuery(".header-search").toggleClass("active");
    jQuery(".search-open").toggleClass("active");
    if (jQuery(".header-search").hasClass("active")) {
      // setTimeout(function () {
      jQuery("#keyword").focus();
      // }, 300)
    } else {
      jQuery("#keyword").blur();
    }
  });
}

function adds($) {
  $("nav .menu-item-has-children").append("<span class='nav-icon'></span>");
}

function menuclick($) {
  jQuery(".nav-icon").click(function () {
    jQuery(this).toggleClass("active");
    jQuery(this)
      .parents("li")
      .siblings("li")
      .children(".nav-icon")
      .removeClass("active");
    jQuery(this).siblings(".sub-menu").slideToggle();
    jQuery(this).parents("li").siblings("li").children(".sub-menu").slideUp();
    $("body").click(function (e) {
      var target = $(e.target);
      if (!target.is(".nav-icon*")) {
        if ($(".nav-icon").hasClass("active")) {
          $(".nav-icon").removeClass("active");
        }
        $(".sub-menu").fadeOut(400);
      }
    });
  });
}

function body_close($) {
  jQuery("body").click(function (e) {
    var target = jQuery(e.target);
    if (
      !target.is(".header-search,.header-search *,.search-open,.search-open *")
    ) {
      if (jQuery(".header-search").hasClass("active")) {
        jQuery(".header-search").removeClass("active");
        jQuery(".search-open").removeClass("active");
        jQuery("#keyword").blur();
      }
    }
  });
}

function stickyMenu($) {
  if (navigator.userAgent.match(/Trident\/7\./)) {
    document.body.addEventListener &&
      document.body.addEventListener("mousewheel", function () {
        event.preventDefault();
        var wd = event.wheelDelta;
        var csp = window.pageYOffset;
        window.scrollTo(0, csp - wd);
      });
  }
  var sh = $(window).scrollTop();
  if (sh > 15 && !$(".header").hasClass("is-stuck")) {
    $(".header").addClass("is-stuck");
  } else if (sh <= 15 && $(".header").hasClass("is-stuck")) {
    $(".header").removeClass("is-stuck");
  }
  if (sh > 15 && !$(".search-open").hasClass("is-stuck")) {
    $(".search-open").addClass("is-stuck");
  } else if (sh <= 15 && $(".search-open").hasClass("is-stuck")) {
    $(".search-open").removeClass("is-stuck");
  }
}

function faqToggle($) {
  $("body").on("click", ".select-list span", function () {
    jQuery(this).toggleClass("active");
    jQuery(this).parents(".select-list").children("ul").fadeToggle(400);
    jQuery(this)
      .parents(".select-list")
      .siblings(".select-list")
      .children("ul")
      .fadeOut(400);
    jQuery(this)
      .parents(".select-list")
      .siblings(".select-list")
      .children("span")
      .removeClass("active");
  });

  $("body").click(function (e) {
    var target = $(e.target);
    if (!target.is(".select-list span*")) {
      if ($(".select-list span").hasClass("active")) {
        $(".select-list span").removeClass("active");
      }

      $(".select-list ul").fadeOut(400);
    }
  });
  $("body").on("click", ".select-list ul li", function () {
    var texts = jQuery(this).text();
    // jQuery(this).addClass("is-active").siblings().removeClass("is-active");
    console.log(texts);
    jQuery(this)
      .parents(".select-list ul")
      .siblings("span")
      .children("input")
      .val(texts);
    $(".select-list").removeClass("active");
    $(".select-list ul").fadeOut(400);
  });

  // jQuery(".select-list").each(function(){
  // 	jQuery(this).find("is-active")
  // 	console.log(jQuery(this).find("is-active").html())
  // })
}

function physiotherapist($) {
  jQuery("body").on("click", ".physiotherapist-detail-box", function (event) {
    event.stopPropagation();
  });

  jQuery("body").on("click", ".full-width-box .close-back", function (event) {
    event.stopPropagation();
    event.preventDefault();
    $(".full-width-box").fadeOut(300);
    jQuery("body").removeClass("no-scroll");
  });
  jQuery("body").on("click", ".full-width-box", function (event) {
    event.stopPropagation();
    $(".full-width-box").fadeOut(300);
    jQuery("body").removeClass("no-scroll");
  });

  jQuery("body").on(
    "click",
    ".physiotherapist-box .physiotherapist-list .popup-btn",
    function (event) {
      jQuery("body").addClass("no-scroll");
      event.preventDefault();
      $(this).siblings(".full-width-box").fadeIn(300);
    }
  );
}

function sliders($) {
  $(".conditons-right").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: false,
    draggable: false,
  });

  $(".conditons-left").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: ".conditons-right",
    arrows: false,
    dots: false,
    focusOnSelect: true,
    vertical: true,
    autoplay: false,
    swipe: true,
    swipeToSlide: true,
    // speed: 0,
    // infinite:false,
    // edgeFriction:0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // $('.conditons-left').on('wheel', (function(e) {
  //   e.preventDefault();

  //   if (e.originalEvent.deltaY > 0) {
  //     $(this).slick('slickNext');
  //   } else {
  //     $(this).slick('slickPrev');
  //   }
  // }));

  let blocked = false;
  let blockTimeout = null;
  let prevDeltaY = 0;

  var i = 0;

  $(".conditons-left").on("mousewheel DOMMouseScroll wheel", function (e) {
    let deltaY = e.originalEvent.deltaY;
    e.preventDefault();
    e.stopPropagation();

    clearTimeout(blockTimeout);
    blockTimeout = setTimeout(function () {
      blocked = false;
    }, 50);

    $(this).on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        i = nextSlide;
      }
    );

    //console.log(i);

    if (
      (deltaY > 0 && deltaY > prevDeltaY) ||
      (deltaY < 0 && deltaY < prevDeltaY) ||
      !blocked
    ) {
      blocked = true;
      prevDeltaY = deltaY;

      if (deltaY > 0) {
        //$(this).slick('slickNext');
        $(this).slick("slickGoTo", i + 3);
        //.slick('slickGoTo', nextSlide);
      } else {
        //$(this).slick('slickPrev');
        $(this).slick("slickGoTo", i - 3);
      }
    }
  });
}

jQuery(document).ready(function () {
  menuHamburger(jQuery);
  wowo(jQuery);
  search(jQuery);
  body_close(jQuery);
  faqToggle(jQuery);
  sliders(jQuery);
  stickyMenu(jQuery);
  physiotherapist(jQuery);
  adds(jQuery);
  menuclick(jQuery);
});

jQuery(window).scroll(function () {
  wowo(jQuery);
  stickyMenu(jQuery);
});
